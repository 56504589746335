import React, { Component, Fragment ,useState } from 'react'

// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

import loader from "../assets/images/loader.svg"



const Loader   = (props) => { 
    return (
		<Fragment> 
			<div className="loader">
				<img src={loader} alt="loader"/>
			</div>	
		</Fragment>
    )
}
 
 export default Loader