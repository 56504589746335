import React,{useMemo}from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement, 
  useStripe, 
  useElements,
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button, Form, FormGroup, Input, Modal, ModalBody
} from 'reactstrap';

const useOptions = () => {
  
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    })    
  );

  return options;
};

const CheckoutForm = () => {

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

   const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });
    console.log("[PaymentMethod]", payload);
  };

  return (

    <form className="checkout-form" onSubmit={handleSubmit}>
        <FormGroup>
            <label>ENTER CARD INFO</label>
            <div className="input-grp card-name">
                 <CardNumberElement/>
            </div>
            <div className="input-grp card-month">
                 <CardExpiryElement/>
            </div>
            <div className="input-grp card-cvc">
                 <CardCvcElement/>
            </div>
            <div className="signup-btn-sec">
                <button type="submit" disabled={!stripe}>
                  Pay Now
                </button>                
            </div>
        </FormGroup>
    </form>
    
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default App;