const loadPlayer = (callback) => {

  var myobj = document.getElementById("loadPlayer");
  if(myobj)
    myobj.remove();

  var myobj1 = document.getElementById("common_partner_plugins");
  if(myobj1)
    myobj1.remove();

  var myobj2 = document.getElementById("common_partner_functions");
  if(myobj2)
    myobj2.remove();

  //css

  var myobj3 = document.getElementById("player_only");
  if(myobj3)
    myobj3.remove();

  var myobj4 = document.getElementById("player_resp");
  if(myobj4)
    myobj4.remove();

  var myobj5 = document.getElementById("core_webrtc_beta");
  if(myobj5)
    myobj5.remove();

  var myobj6 = document.getElementById("player_webrtc_only");
  if(myobj6)
    myobj6.remove();

  const existingScript = document.getElementById('loadPlayer');
  if (!existingScript) {
    const bkurl =process.env.REACT_APP_BACKENDURL;
    const script = document.createElement('script');
    script.src = bkurl+'audioplayer.js';
    script.id = 'loadPlayer';
    document.body.appendChild(script);
    script.onload = () => { 
      if (callback) callback();
    };

  }
  if (existingScript && callback) callback();
};
export default loadPlayer;