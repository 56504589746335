import React, { Component, Fragment ,useState,useEffect,useMemo } from 'react'
import {loadStripe} from '@stripe/stripe-js';
import { Helmet } from 'react-helmet'
import {
  CardElement, 
  useStripe, 
  useElements,
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';

// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// subscription page images
import aboutvideo from "../assets/videos/about-video.mp4";
// subscription page images
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
    NavLink,
    Button, Modal, ModalBody,FormGroup
  } from 'reactstrap';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import InnerMenu from './userLinks.jsx';
import http from '../httpService/httpService'
import { ToastContainer,toast } from 'react-toastify';
import Loader from '../components/loader.jsx';
import stripimg from "../assets/images/strip-img.png"
import Player from './Player.jsx';
import loadPlayer from './loadPlayer.js';

const useOptions = () => {
  
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    })    
  );

  return options;
}

const CheckoutForm = (props) => {
    const sData = props.subData;

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions(); 
    const [ loader , setLoader] = useState(false);
    const signupmodal3 = () => setModal3(!modal3);
    const [modal3, setModal3] = useState(false);

    const handleSubmitStripeForm = async event => {
    event.preventDefault();
    setLoader(true);
    if (!stripe || !elements) {
      setLoader(false);
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });

    if(payload.error !== undefined){
        setLoader(false);
        document.getElementById("stripeErr").innerHTML = payload.error.message;    
    }else{
        document.getElementById("stripeErr").innerHTML ='';
        setLoader(true);

        let subsctiption_id = sData.u_subscription;
        let payment_id = payload.paymentMethod.id;
        const token = localStorage.getItem('marketAuthToken');
        http.post(process.env.REACT_APP_API_URL+'/update-subscription', { payment_id,subsctiption_id},{
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
        .then((response) => {  

          setLoader(false);      
          
          
          const resData = response.data; 
          if(resData.meta.subscription && resData.meta.subscription.status == 'incomplete' && resData.meta.subscription.latest_invoice.payment_intent != undefined){              
            handleCardAuth(resData.meta.subscription.latest_invoice.payment_intent,resData.meta.message,resData.data.activeSubscription)
          }else{
            toast.success(resData.meta.message);
            setModal3(true)
            document.querySelector('.card-close').click();
            props.setSubscription(resData.data.activeSubscription)
          }

          
        })
        .catch((error) => {
          setLoader(false);
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.meta.message);
          }
        });

    }
  };

  const handleCardAuth = (paymentIntent,sucMsg,activeSubscription) =>{
    stripe.handleCardPayment(paymentIntent.client_secret).then((res) =>{
       if(res.error){
          toast.success('Stripe authentication fail.');
       }else{
           toast.success(sucMsg);
           document.querySelector('.card-close').click();
           setModal3(true)
           props.setSubscription(activeSubscription)
       }
    })
 }

  return (
    <div>
        
        {loader && <Loader />}
        <form className="checkout-form" onSubmit={handleSubmitStripeForm}>
            <FormGroup>
                <label>ENTER CARD INFO</label>
                <div className="input-grp card-name">
                     <CardNumberElement/>
                </div>
                <div className="input-grp card-month">
                     <CardExpiryElement/>
                </div>
                <div className="input-grp card-cvc">
                     <CardCvcElement/>
                </div>
                <div className="signup-btn-sec">
                    <button className="btn common-btn" type="submit" disabled={!stripe || loader}>
                      Pay Now
                    </button>   
                    <img src={stripimg} alt="stripimg"/>             
                </div>
               
            </FormGroup>
        </form>
        <span className="errors" id="stripeErr"></span>

        <Modal isOpen={modal3} toggle={signupmodal3} className="signup-modal" >
            <ModalBody>
                <div className="thankyou-section">
                    <h2>Thank you</h2>
                    <h4>Your subscription is updated!</h4>                  
                </div>
            </ModalBody>
            <a className="close-modal-link"  onClick={signupmodal3}></a>
        </Modal>

    </div>
  );
};

const TITLE = 'Subscription | Market Squawk'
const Suscription   = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [ subscription_item_audio , setSubscription_item_audio] = useState([]);
    const [ subscription_item_audio_new , setSubscription_item_audio_new] = useState([]);
    const [ mySubascription , setMySubscription ] = useState( { 'interval_count':'',id:'',intervals:'','price':'',amt1:'',amt2:'' } );
    const [ loader , setLoader] = useState(false);   

    useEffect(() => {
        // loadPlayer(() => {
        //   setLoaded(true);
        // });
        if(!props.fetched) {
           window.scrollTo(0, 0)
            http.get(process.env.REACT_APP_API_URL + '/subscription-price-list?offset=0&limit=8')
            .then((response) => {
                let subpackage = response.data.data;
                let audio = subpackage.filter(pc => (pc.type == 'audio'));
                let audionews = subpackage.filter(pc => (pc.type == 'newsaudio'));                
                setSubscription_item_audio(audio);
                setSubscription_item_audio_new(audionews);
            });
        }

        if(!props.fetched) {
            const token = localStorage.getItem('marketAuthToken');
            http.get(process.env.REACT_APP_API_URL + '/user-view-profile',{
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then((response) => {

                let newData = { ...mySubascription };                
                newData['interval_count']=response.data.data.activeSubscription != undefined ? response.data.data.activeSubscription.interval_count : '' ;            
                newData['id']=response.data.data.activeSubscription != undefined ? response.data.data.activeSubscription.id : '' ;            
                newData['intervals']=response.data.data.activeSubscription != undefined ? response.data.data.activeSubscription.intervals : '' ;            
                newData['price']=response.data.data.activeSubscription != undefined ? response.data.data.activeSubscription.price : '' ;            
                
                if(newData['interval_count'] == 5 ){
                  newData['interval_count'] = 1
                  newData['intervals'] = 'week'
                }
                if(newData['interval_count'] == 28 ){
                  newData['interval_count'] = 1
                  newData['intervals'] = 'month'
                }
                var price = response.data.data.activeSubscription != undefined ? response.data.data.activeSubscription.price : '' ;
                var amt = price.split(".");  
                newData['amt1'] = amt[0];
                newData['amt2'] = amt[1];
                setMySubscription(newData);  
                        
            }).catch((error) => {
            setLoader(false);
            toast.error('Something went wrong.');
          });
        }

        if(!props.fetched) {

          // const script = document.createElement("script");
          // script.src = "https://code.jquery.com/jquery-3.2.1.min.js";
          // script.async = true;
          // document.body.appendChild(script);


          // const script1 = document.createElement("script");
          // script1.src = "//www.livesquawk.com/partner_player_only/js/include_tms_webrtc_only.js?v=3";
          // script1.async = false;
          // document.body.appendChild(script1);
        }

    }, []);

    // Cancel subscription     ---------- Start
    const [ cancelSubData , setCancelSubData] = useState({ terms : 0 });
    const [ cancelSubErrors , setCancelSubErrors] = useState( { err_terms:'' } );
    const validateCancelSubription = () => {
           
        const cancelSubErr = {};
        if(cancelSubData.terms === 0 || cancelSubData.terms === ""){
          cancelSubErr.err_terms = 'Please accept terms and condition';
        }
        setCancelSubErrors(cancelSubErr);
        return Object.keys(cancelSubErr).length === 0 ? null : cancelSubErr;

    }

    const cancelSubscription = e => {
        e.preventDefault(); 
        if( validateCancelSubription() === null){
          setLoader(true);  
          const token = localStorage.getItem('marketAuthToken');
          http.post(process.env.REACT_APP_API_URL+'/cancle-subscription',{},{
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              })
          .then((response) => { 
              setLoader(false);   
              toast.success(response.data.meta.message);
          }).catch((error) => {
            setLoader(false);
            toast.error('Something went wrong.');
          });
        }
    }

    const checkTermsCancelSub = (e) => { 
        const newArr = { ...cancelSubData }
        newArr['terms']=e.currentTarget.checked == true ? 1 : 0 ;
        setCancelSubData(newArr);        
    }
    // Cancel subscription     ---------- End

    

    // Update Subscription  ---------- Start
    const [ subValue , setsubValue] = useState({ days : '',amt1:'',amt2:'' });
    const [ subData , setSubData ] = useState( { u_subscription:'',terms:0 } );
    const [ subErrors , setSubErrors] = useState( {err_u_subscription:'',err_u_terms:''} );
    const [ modal2, setModal2] = useState(false);
    const signupmodal2 = () => setModal2(!modal2);
    const [loaded, setLoaded] = useState(true);

    const checkTermsUpdateSub = (e) => { 
        const newArr = { ...subData }
        newArr['terms']=e.currentTarget.checked == true ? 1 : 0 ;
        setSubData(newArr);        
    }

    const handleChangePlan = (e) => { 
        const regD = { ...subData };
        regD['u_subscription']=e.currentTarget.value;
        setSubData(regD);

        if(e.currentTarget.selectedOptions[0].getAttribute("days")){
          const days = e.currentTarget.selectedOptions[0].getAttribute("days");
          const amount = e.currentTarget.selectedOptions[0].getAttribute("amount");
          var amt = amount.split(".");
          
          const newAmt = { ...subValue };
          newAmt['days'] = days;
          newAmt['amt1'] = amt[0];
          newAmt['amt2'] = amt[1];
          setsubValue(newAmt);
        }
    } 

    const validateUpdateSubscription = () => {
           
        const subErrors = {};        
        if(subData.u_subscription === ''){
          subErrors.err_u_subscription = 'Please select a subscription';
        }
        if(subData.terms === 0 || subData.terms === ""){
          subErrors.err_u_terms = 'Please accept terms and condition';
        }
        setSubErrors(subErrors);
        return Object.keys(subErrors).length === 0 ? null : subErrors;

    }
    const updateSubscription = e => {        
        e.preventDefault();       
        
        if( validateUpdateSubscription() === null){            
            setModal2(true)            
        }       
    }

    //Stripe     
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const getNewSubscriptionData = (data) => {      
      
      let newData = { ...mySubascription };                
      newData['interval_count']=data != undefined ? data.interval_count : '' ;            
      newData['id']=data != undefined ? data.id : '' ;            
      newData['intervals']=data != undefined ? data.intervals : '' ;            
      newData['price']=data != undefined ? data.price : '' ;            
      

      if(newData['interval_count'] == 5 ){
        newData['interval_count'] = 1
        newData['intervals'] = 'week'
      }
      if(newData['interval_count'] == 28 ){
        newData['interval_count'] = 1
        newData['intervals'] = 'month'
      }

      var price = data != undefined ? data.price : '' ;
      var amt = price.split(".");  
      newData['amt1'] = amt[0];
      newData['amt2'] = amt[1];
      setMySubscription(newData);  

    }
    // Update Subscription  ---------- End
    return (
         <Fragment>
          <Helmet>
            <title>{ TITLE }</title>
          </Helmet>
         {loader && <Loader />} 

         <ToastContainer/>

         {/* Header */}
             
         <Navigation/>
 
         {/* Header */}
            
         {/* Dashboard-header */}

         <div className="dashboard-header-sec">
            <div className="dashboard-header">
              {loaded ? <Player /> : ''}
              {/* <div id="livesquawk_player_outer">
               </div>
               
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="volume-section">
                                <p>Volume Adjust</p>
                            </div>
                        </Col>
                        <Col md="6" className="middle-header">
                            <div className="on-air-labels">
                                <span>ON AIR</span>
                                <span className="on-air-icon"></span>
                                <span>ON AIR</span>
                            </div>
                        </Col>
                        <Col md="3">
                        
                        </Col>
                    </Row>
                </Container> */}
            </div>
            <div className="dahboard-nav-sec">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                        <Col md="6">
                            <InnerMenu/>
                        </Col>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <video  loop="" muted="muted" data-origwidth="0" data-origheight="0"  loop>
                    <source src={aboutvideo} type="video/mp4" />
             </video>
        </div>

        {/* Dashboard-header */}

        {/* Subscription-section */}

        <div className="subscription-section">
             <Container>
                 <Col md={12}>
                    <Modal isOpen={modal2} toggle={signupmodal2} className="signup-modal card-modal" >
                        <ModalBody>
                            <h2>Sign Up</h2>
                            <div className="signup-desc-sec">
                                <div className="signup-desc">
                                    <h4>Your Package</h4>
                                    <p>Your Live Subscription will auto-renew after the initial period has come to an end. You can cancel anytime.</p>
                                </div>
                                <div className="signup-plan-sec">
                                    <div className="signup-plan-details">
                                        <span className="plan-days">{ subValue.days }</span>
                                        <h3>${ subValue.amt1 }<span>.{ subValue.amt2 }</span></h3>
                                        <p>Follow a story through</p>
                                    </div>
                                    <a href="javascript:" onClick={ setModal2}>UPDATE</a>
                                </div>
                            </div>
                            <div className="checkout-sec">
                                <h4>Checkout</h4>
                                <p>Our payment system is secured and powered by Stripe</p>
                                <Elements stripe={stripePromise}>
                                  <CheckoutForm subData ={ subData } setSubscription={ getNewSubscriptionData } />
                                </Elements>
                            </div>
                        </ModalBody>
                        <a className="close-modal-link card-close"  onClick={signupmodal2}></a>
                    </Modal>                    
                </Col> 

                 <h2>My Subscription</h2>
                 <div className="subscription-details">
                    <div className="signup-plan-sec">
                        <span className="subscription-subtitle">LIVE SUBSCRIPTION</span>
                        <div className="signup-plan-details">
                            <span className="plan-days">{ mySubascription.interval_count } { mySubascription.intervals }</span>
                            <h3>${ mySubascription.amt1 }<span>.{ mySubascription.amt2 }</span></h3>
                            <p>Follow a story through</p>
                        </div>
                    </div>
                    <div className="sunscription-desc">
                        <h6>Manage your Account Subscription</h6>
                        <p>Your Live Subscription will renew automatically after the initial period has come to an end. You have the option of updating your subscription on this page. <b className='cancelsubc'>To cancel your subscription please scroll down this page.</b> Note that any changes that you make will kick-in at the end of the initial period agreed. Finally, you can choose to cancel your subscription, which will prevent account to be renewed.</p>
                        <div className="package-section update-package">
                            <h4>Update Package</h4>
                            <div className="package-dropdown">
                               {/*  <select name="u_subscription" id="u_subscription" onChange={ handleChangePlan }>
                                    <option value=''>Select Plan</option>
                                    { subscription_item.map((item,k) => 
                                        <option key = { k } value={ item.id }  days={ item.interval } amount ={ item.amount }  >{ item.interval } Subscription - ${ item.amount }</option>
                                    )} 
                                </select> */}

                                <select name="u_subscription" id="u_subscription" onChange={ handleChangePlan }>
                                    <option value=''>Select Plan</option>

                                    <optgroup label="Audio + Newsfeed">
                                      { subscription_item_audio_new.map((item,k) => 
                                          <option key = { k } value={ item.id }  days={ item.interval } amount ={ item.amount }  >{ item.interval } Subscription - ${ item.amount }</option>
                                      )} 
                                    </optgroup>

                                     <optgroup label="Audio">
                                      { subscription_item_audio.map((item,k) => 
                                          <option key = { k } value={ item.id }  days={ item.interval } amount ={ item.amount }  >{ item.interval } Subscription - ${ item.amount }</option>
                                      )} 
                                    </optgroup>
                                    
                                </select>

                                { subErrors.err_u_subscription && <p className="error-text"> <em> { subErrors.err_u_subscription } </em></p> }
                            </div>
                            <div className="remember-checkbox">
                                <input type="checkbox" name="remember-input" onChange={ checkTermsUpdateSub } />
                                <span><i className="fa fa-check" aria-hidden="true"></i></span>
                                <p>I agree to the <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} target='_blank'>terms of use</a></p>
                                { subErrors.err_u_terms && <p className="error-text"> <em> { subErrors.err_u_terms } </em></p> }
                            </div>
                            <button type="button" className="btn common-btn" onClick={ updateSubscription }>CONFIRM CHANGES</button>
                        </div>
                        <div className="seprator-line"></div>
                        <div className="package-section cancel-package">
                            <h4>Cancel Subscription</h4>
                            <p>You can choose to cancel your subscription, which will prevent your card from being debited after the initial period agreed.</p>
                            <div className="remember-checkbox">
                                <input type="checkbox" name="remember-input" onChange={ checkTermsCancelSub } />
                                {
                                  cancelSubData.terms == 1 ? <span> <i className="fa fa-check" aria-hidden="true"></i> </span> : <span></span>
                                }                                
                                <p>I agree to the <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} target='_blank'>terms of use</a></p>
                                { cancelSubErrors.err_terms && <p className="error-text"> <em> { cancelSubErrors.err_terms } </em></p> }
                            </div>
                            <button type="button" className="btn primary-btn" onClick={ cancelSubscription }>STOP SUBSCRIPTION</button>
                        </div>
                    </div>
                 </div>
             </Container>
        </div>

        {/* Subscription-section */}
 
         {/* Footer */}
 
         <FooterSection/>  
         
 
         {/* Footer */}
 
 
         </Fragment>)
}
 
 export default Suscription