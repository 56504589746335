import React,{useState,useRef,useEffect} from 'react'
import { Container,Row, Col,NavLink,Modal, ModalBody} from 'reactstrap';
import { Link } from 'react-router-dom'
import footerlogo from "../assets/images/footer-logo.svg";
import logotiktok from "../assets/images/tik-tok.svg";
import logotiktokcolored from "../assets/images/tik-tok-coloured.svg";
import { history } from '../index';
import { useLocation } from 'react-router-dom';
import http from '../httpService/httpService'

const FooterSection = (props) =>{
    const location = useLocation();
    const redirectPage = (redTo,id) => {
            
        var itemArr = [process.env.REACT_APP_PREFIX+"news",process.env.REACT_APP_PREFIX+"subscription",process.env.REACT_APP_PREFIX+"settings"];
       
        if( itemArr.indexOf(location.pathname) > -1){
            setModal(true);
            setredToPage(redTo);
        }else{

            if(props.onClickMenu !== undefined && id != ''){
                props.onClickMenu(id);
            }else{
                window.location.href = redTo;
            }
            // console.log(location.pathname);
            //window.location.href = redTo;
            
        }
        

    }
    const pagemodal = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [redToPage, setredToPage] = useState(`${process.env.PUBLIC_URL}`);

    const [links, setLinks] = useState({ 
        fb : 'https://www.facebook.com',
        in : 'https://www.instagram.com',
        tw : 'https://www.twitter.com',
        rdt : 'https://www.reddit.com',
        tik : 'https://www.tiktok.com',
        footer_text : "The Market Squawk lorem ipsum has been the industry's standard dummy text in the United Kingdom since the 2010 with the global news."
    });

    useEffect(() => {
        if(!props.fetched) {

            http.get(process.env.REACT_APP_API_URL + '/site-config')
            .then((response) => {
               
               setLinks({
                   fb : response.data.data[5]? response.data.data[5].facebook : '#' ,
                   tw : response.data.data[6] ? response.data.data[6].twitter :  '#' ,
                   in : response.data.data[7] ? response.data.data[7].instagram :  '#',
                   rdt : response.data.data[8] ? response.data.data[8].reddit :  '#',
                   tik : response.data.data[9] ? response.data.data[9].tiktok :  '#',
                   footer_text : response.data.data[10] ? response.data.data[10].front_footer_text :  "The Market Squawk lorem ipsum has been the industry's standard dummy text in the United Kingdom since the 2010 with the global news.",
               });
            });
        }
    }, []);

    return (

        <footer>
             <Container>
                 <Row>
                     <Col md="6">
                         <div className="footer-left-sec">
                             <ul className="footer-menu">
                                 <li>
                                     <Link onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}`,'')}>HOME</Link>
                                 </li>
                                 <li>
                                     <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#features`,'features')}>FEATURES</NavLink>
                                 </li>
                                 <li>
                                    <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#about`,'about')} >ABOUT</NavLink>
                                 </li>
                                 <li>
                                    <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#pricing`,'pricing')} >PRICING</NavLink>
                                 </li>
                                 <li>
                                    <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#blog`,'blog')}>BLOG</NavLink>
                                 </li>
                             </ul>
                             <div className="footer-desc-sec">
                                 <img  src={footerlogo}/>
                                 <div className="footer-desc">
                                     <p>{links.footer_text }</p>
                                 </div>
                             </div>
                         </div>
                     </Col>
                     <Col md="6">
                         <div className="footer-right-sec">
                             <ul className="footer-right-menu">
                                 <li>
                                     <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/privacy-policy`,'')}>PRIVACY</NavLink>
                                 </li>
                                 <li>
                                     <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/terms-and-conditions`,'')} >TERMS</NavLink>
                                 </li>
                                 <li>
                                     <NavLink  onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#contact`,'contact')}>CONTACT</NavLink>
                                 </li>
                             </ul>
                             <ul className="social-links">
                                <li>                                    
                                     { 
                                        (links.tik !== '#') ? <a href={links.tik} target='_blank' class='tiktok'><img  src={logotiktok} class='tiktok-main-img'/><img  src={logotiktokcolored} class='hoverimg'/></a> : <a href='#' class='tiktok'><img  src={logotiktok} class='tiktok-main-img'/><img  src={logotiktokcolored} class='hoverimg'/></a> 
                                     }
                                 </li>
                                 <li>
                                     
                                     { 
                                        (links.tw !== '#') ? <a href={links.tw} target='_blank'><i className="fa fa-twitter" aria-hidden="true"></i></a> : <a href='#' ><i className="fa fa-twitter" aria-hidden="true"></i></a> 
                                     }
                                 </li>
                                  <li>
                                     { 
                                        (links.rdt !== '#') ? <a href={links.rdt} target='_blank'><i className="fa fa-reddit" aria-hidden="true"></i></a> : <a href='#' ><i className="fa fa-linkedin" aria-hidden="true"></i></a> 
                                     }
                                     
                                 </li>
                                 <li>
                                     { 
                                        (links.in !== '#') ? <a href={links.in} target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a> : <a href='#' ><i className="fa fa-linkedin" aria-hidden="true"></i></a> 
                                     }
                                     
                                 </li>                                
                                 <li>
                                     { 
                                        (links.fb !== '#') ? <a href={links.fb} target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a> : <a href='#' ><i className="fa fa-facebook" aria-hidden="true"></i></a> 
                                     }
                                 </li>
                                 
                             </ul>
                         </div>
                     </Col>
                 </Row>
             </Container>
             <Modal isOpen={modal} toggle={pagemodal} className="page-modal" >
                    <ModalBody>
                        <h3>Are You Sure ?</h3>
                        <p>Are you sure you want to navigate away from this page? Leaving will STOP your in-page audio-connection... </p>
                         <NavLink className="primary-btn btn" href={ redToPage }> Leave Page Anyway</NavLink>
                        <button className="primary-btn btn" onClick={pagemodal} >Stay on This Page</button>
                    </ModalBody>
                </Modal>
         </footer>
        )
    }
 export default FooterSection