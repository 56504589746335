import React from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    TabContent, TabPane,
  } from 'reactstrap';
import { Container,Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom'
// common images
import http from '../httpService/httpService'
import { history } from '../index'
// common images;
const InnerMenu = (props) =>{
    const pageURL = window.location.href;
    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)

    return (
        <Nav className="dashboard-nav" navbar>
            <NavItem>
                <a href={`${process.env.PUBLIC_URL}/news-feed`} className={ getLastItem(pageURL) == 'news-feed' ? 'active' : ''  } > NEWSFEED</a>
            </NavItem>     
            <NavItem>
                <a href={`${process.env.PUBLIC_URL}/news`} className={ getLastItem(pageURL) == 'news' ? 'active' : ''  } > NEWS</a>
            </NavItem>
            <NavItem>
                <a href={`${process.env.PUBLIC_URL}/subscription`}  className={ getLastItem(pageURL) == 'subscription' ? 'active' : ''  } > SUBSCRIPTION</a>
            </NavItem>
            <NavItem>
                <a href={`${process.env.PUBLIC_URL}/settings`}  className={ getLastItem(pageURL) == 'settings' ? 'active' : ''  }> SETTINGS</a>
            </NavItem>
        </Nav>
    )
}


export default InnerMenu