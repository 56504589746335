import React, { Component, Fragment ,useState,useEffect } from 'react'
import { Helmet } from 'react-helmet'

// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// Blog Details Page
import blogbg from "../assets/images/blog-bg.jpg"
// Blog Details Page
import { Link,useParams,withRouter } from 'react-router-dom';

import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
  } from 'reactstrap';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import Loader from '../components/loader.jsx';
import http from '../httpService/httpService'
import { ToastContainer,toast } from 'react-toastify';
var parse = require('html-react-parser');
const TITLE = 'Terms & Conditions | Market Squawk'
const Blogdetails   = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [ loader , setLoader] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const urlparams = useParams();
    
   const [ page_detail , setPageDetail] = useState({title : '',created_at:'',description:'',image:''});
    
    useEffect(() => {
        if(!props.fetched) {
             window.scrollTo(0, 0)
            setLoader(true)

            http.get(process.env.REACT_APP_API_URL + '/cms/3')
            .then((response) => {
                setPageDetail(response.data.data);
                setLoader(false)
            });
        }
    }, []);

    const cleanDesc = (data) => {
      return data.replace(/<[^>]+>/g, '');
    }

    return (
        <Fragment>

        {/* Header */}
           
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
        {loader && <Loader />} 
        <Navigation />
 
         {/* Header */}
            

        {/* Blog Details Section */}

        <Container>
            <div className="">                
                <div className="">
                {/* <h3 className="blog-title">{ page_detail.title }</h3> */}               
                    { parse(page_detail.description) }
                </div>
            </div>
        </Container>

        {/* Blog Details Section */}

 
         {/* Footer */}
 
         <FooterSection/>  
         
 
         {/* Footer */}
 
 
         </Fragment>)
}
 
 export default Blogdetails