import React, { Component, Fragment ,useState,useEffect,useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';

import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// Home Page component & css

import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import classnames from 'classnames';
// Home Page component & css


// Hoem Page images
import featurecost from "../assets/images/feature-cost.svg";
import featureinstant from "../assets/images/feature-instant.svg";
import featurenocontract from "../assets/images/feature-no-contract.svg";
import aboutimg from "../assets/images/about-img.png";
import aboutvideo from "../assets/videos/about-video.mp4";
import aboutreaserch from "../assets/images/about-reaserch.png";
import aboutteam from "../assets/images/about-team.png";
import spotifylogo from "../assets/images/spotify-logo.png";
import companyslidebg from "../assets/images/compnay-slide-bg.jpg";
import blogbg from "../assets/images/blog-bg.png"
import samplebg from "../assets/images/sample-bg.png";
import { connect } from 'react-redux';

import newsimg from "../assets/images/home-news-img.jpg";
// Hoem Page images

import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import http from '../httpService/httpService'
import { ToastContainer,toast } from 'react-toastify';
import { verifyEmail } from '../helper';
import Loader from '../components/loader.jsx';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	TabContent, TabPane,
	Button, Form, FormGroup, Input,  Modal, ModalBody
  } from 'reactstrap';

  var companyslider = {
	dots: true,
	arrows:false,
	infinite: true,
	speed: 2000,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay:true,
	autoplaySpeed:4000,
	pauseOnHover:false,
	slide: 'div'
  };
  var blogslider = {
	dots: false,
	arrows:true,
	infinite: false,
	speed: 1000,
	slidesToShow: 4,
	slidesToScroll: 1,
	responsive: [
		{
		  breakpoint: 1199,
		  settings: {
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,		  }
		},
		{
		  breakpoint: 991,
		  settings: {
			slidesToShow: 2,
			slidesToScroll: 1,
			infinite: true
		  }
		},
		{
		  breakpoint: 576,
		  settings: {
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true
		  }
		}
	  ]
};
var parse = require('html-react-parser');

const TITLE = 'Home | Market Squawk'
var parse = require('html-react-parser');
const Home   = (props) => {

		useEffect(() => {
	        if(props.userData.dispatchType === 'USER_PROFILE' && props.userData.token === localStorage.getItem('marketAuthToken')){
	            setisLogin(true)
	        }
	    }, [props.userData])

	    const isFirstRun = useRef(true);
	    useEffect (() => {
	        if (isFirstRun.current) {
	            isFirstRun.current = false;
	            return;
	        }
	        if(props.userData.dispatchType === 'USER_PROFILE' && props.userData.token === localStorage.getItem('marketAuthToken')){
	          setisLogin(true)  
	        }
	    }, [props.userData]);

	    const [isLogin, setisLogin] = useState(false);
		const [isOpen, setIsOpen] = useState(false);
		const toggle = () => setIsOpen(!isOpen);
		const videomodal = () => setModal(!modal);
		const [ subscription_item_audio , setSubscription_item_audio] = useState([]);
		const [ subscription_item_audio_new , setSubscription_item_audio_new] = useState([]);
		const [ ads_item , setAdsItem] = useState([]);
		const [ latest_blog_item , setLatestBlogItem] = useState([]);
		const [ popular_blog_item , setPopularBlogItem] = useState([]);

		const [activeTab, setActiveTab] = useState('1');
		const [activeOption, setActiveOption] = useState('1');
		const [modal, setModal] = useState(false);

		// Home Content
		const [keyFeatures, setKeyFeatures] = useState({ 
			title_1 : 'COST EFFECTIVE',
			title_2 : 'INSTANT ACCESS',
			title_3 : 'NO CONTRACT',
			description_1 : 'The Market Squawk service is low cost – just pay and play, use as and when you need it',
			description_2 : 'Instant access and easy to use – you’ll be up and running within minutes',
			description_3 : 'You aren’t tied into a contract, there’s no subscription which means you can come and go as you like',
		});

		const [aboutUs, setAboutUs] = useState({ 
			short_description : 'Lorem Ipsum has been the industry’s standard dummy text in the United Kingdom since the 2020 in News and Media.',
			title_1 : 'SQUAWK TEAM',
			image_1 : aboutteam,
			description_1 : 'We are seasoned professionals, knowledgeable and experienced in and around the markets. We know and understand what’s important to traders and investors. Our experience from trading, broking and risk managing have helped shape a service that provides listeners insight and appreciation of the news.',
			title_2 : 'THE RESEARCHERS',
			image_2 : aboutreaserch,
			description_2 : 'Our team scrutinise all the major newswires, television channels, websites, blogs and social media platforms for content with market-moving potential.',
		});


		const [newsfeed, setNewsFeed] = useState({ 
			news_feed_title : 'Lorem Ipsum is simply',
			news_feed_description : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
			news_feed_opt1 : 'Lorem Ipsum',
			news_feed_opt2 : 'Lorem Ipsum',
			news_feed_opt3 : 'Lorem Ipsum',
			news_feed_image : newsimg
		});

		const [getInTouch, setGetInTouch] = useState({ 
			short_description_2 : 'Lorem Ipsum has been the industry’s standard dummy text in the United Kingdom since the 2020.',			
		});

		const [ourPricing, setOurPricing] = useState({ 
			short_description_1 : 'Lorem Ipsum has been the industry’s standard dummy text in the United Kingdom since the 2020.',			
		});

		const toggletab = tab => {			
			if(activeTab !== tab) setActiveTab(tab);
		}

		const toggleOption = event => {					
			if(activeOption !== event.target.value) setActiveOption(event.target.value);
		}

   		useEffect(() => {
	        if(!props.fetched) {

	        	http.get(process.env.REACT_APP_API_URL + '/home-content')
	            .then((response) => {
	                setKeyFeatures(response.data.data.key_features);
	                setAboutUs(response.data.data.about_us);
	                setNewsFeed(response.data.data.news_feed);
	                setGetInTouch(response.data.data.get_in_touch);
	                setOurPricing(response.data.data.our_pricing);
	            });

	            http.get(process.env.REACT_APP_API_URL + '/subscription-price-list?offset=0&limit=8')
	            .then((response) => {

	            	let subpackage = response.data.data;
	            	let audio = subpackage.filter(pc => (pc.type == 'audio'));
	            	let audionews = subpackage.filter(pc => (pc.type == 'newsaudio'));	            	
	                setSubscription_item_audio(audio);
	                setSubscription_item_audio_new(audionews);
	            });

	            http.get(process.env.REACT_APP_API_URL + '/ads-list?offset=0&limit=10')
	            .then((response) => {
	                setAdsItem(response.data.data);
	            });

	            http.get(process.env.REACT_APP_API_URL + '/blog-list?offset=0&limit=20&filter_by=latest')
	            .then((response) => {
	                setLatestBlogItem(response.data.data);
	            });

	            http.get(process.env.REACT_APP_API_URL + '/blog-list?offset=0&limit=20&filter_by=popular')
	            .then((response) => {
	                setPopularBlogItem(response.data.data);
	            });
	        }
	    }, []);


	    //Contact Form
	    const initContactState = { name : '', email: '',telephone: '',message: '' };
	    const [ contactDetail , setContactDetail ] = useState(initContactState);
    	const [ errors , setErrors] = useState( { errName : '',errEmail : '',errTelephone:'',errMessage:'' } );
    	const [ loader , setLoader] = useState(false);

    	const validate = () => {
           
	        const errors = {};
	        // Name Validation
	        if(contactDetail.name == ''){
	          errors.errName = 'Name is required';
	        }

	        // Email Validation
	        if(contactDetail.email == ''){
	          errors.errEmail = 'Email is required';
	        }else if(!verifyEmail(contactDetail.email)){
	          errors.errEmail = 'Please enter valid email address';
	        } 

	        // Email Validation
	        if(contactDetail.telephone == ''){
	          errors.errTelephone = 'Telephone number is required';
	        }

	        // Message Validation
	        if(contactDetail.message == ''){
	          errors.errMessage = 'Message is required';
	        } 

	        setErrors(errors);
	        return Object.keys(errors).length === 0 ? null : errors;

	    }

	    const handleSubmit = e => {
	    	
	        e.preventDefault();
	        let name =contactDetail.name; 
	        let email =contactDetail.email; 
	        let telephone =contactDetail.telephone; 
	        let message =contactDetail.message; 	        
	        let gc = localStorage.getItem('googleCaptchaToken') ?? '';
	        
	        if( validate() === null){
	            setLoader(true);

	            http.post(process.env.REACT_APP_API_URL+'/contact-us', { name , email, telephone ,message,gc})
	            .then((response) => {   

	                document.getElementById("contact-form").reset();
	                setLoader(false);      
	                toast.success(response.data.meta.message);
	                setContactDetail(initContactState);

	                setTimeout(function(){
					   window.location.reload(1);
					}, 2000);

	            })
	            .catch((error) => {
	              setLoader(false);
	              if(error.response && error.response.status != 200){
	                 toast.error(error.response.data.meta.message);
	              }
	            });
	        }
	       
	    }
	    
	    const handleChange = (e) => {

	        const { name , value  } = e.target;
	        contactDetail[name]=value;
	        setContactDetail(contactDetail);
	    }

	    const cleanDesc = (data) => {
	    	return parse(data.replace(/<[^>]+>/g, '').slice(0,50));
	    }


	    const [siteConf, setSiteConf] = useState({ 
	        email : 'info@themarketsquawk.com',
	        contact : '0203 753 4648',
	        address : 'London Head Office: Unit 414, Coppergate House,10 Whites Row,London E1 7NF'
	    });

	    useEffect(() => {
	        if(!props.fetched) {

	            http.get(process.env.REACT_APP_API_URL + '/site-config')
	            .then((response) => {	            	
	               setSiteConf({
	                   email : response.data.data[1].support_email,
	                   contact : response.data.data[2].support_contact,
	                   address : response.data.data[3].support_address
	               });
	            });
	        }

	        if(!props.fetched) {
	        	var type = window.location.hash.substr(1);
	        	if(type != undefined && type != '' && type!='#'){
	        		if(document.getElementById(type))
	        			document.getElementById(type).scrollIntoView({ behavior: "smooth" });
	        	}
	       	}
	    }, []);

	    const handleMenuClick = (id) => {
	    	if(document.getElementById(id)){
	    		document.getElementById(id).scrollIntoView({ behavior: "smooth" });
	    	}
		    
		};

		const [isPlay, setIsPlay] = useState(false);
	    const  play = () => {
	        var audio = document.getElementById("audio");
	        isPlay == false ? audio.play() : audio.pause();
	        setIsPlay(!isPlay)
	    }


	return (
		<Fragment>
		

		<Helmet>
          <title>{ TITLE }</title>
        </Helmet>
		{/* Header */}			

		{loader && <Loader />} 
		<Navigation onClickMenu= { handleMenuClick } />

		{/* Header */}

		{/* Banner Section */}

		<div className="home-banner-sec">
			<Container>
				<Row>
					<Col md="7">
						<div className="banner-content">
							<h6>A 'PAY AND PLAY'</h6>
							<h1>Market Audio News Feed</h1>
							<p>For traders and investors needing a live financial market newscast. <strong>Find out why the markets move in <span>Real-Time</span></strong></p>
							<div className="banner-btns">
								{ isLogin == true ? 
									<Link to={`${process.env.PUBLIC_URL}/settings`} className="common-btn sign-up-btn btn nav-link">Dashboard</Link>
									:
									<Link to={`${process.env.PUBLIC_URL}/login?signup=true`} className="common-btn sign-up-btn btn nav-link">SIGN ME UP</Link>
								}
								
								<NavLink href="#" className="primary-btn btn" onClick={videomodal}>PLAY VIDEO</NavLink>
								<NavLink className="secondary-btn soundbite-btn common-btn sign-up-btn btn nav-link" href='javascript:' onClick={ play } >{ isPlay == false ? 'Play Soundbite' : 'Pause'}</NavLink>
							
								<audio id='audio' src={ `${process.env.REACT_APP_BACKENDURL}soundbite.mp4` } type="audio/mpeg" ></audio>
							</div>
						</div>
					</Col>
					<Col md="5">
						<div className="banner-video" style={{ backgroundImage: 'url(' + samplebg + ')' }}>
							<a className="video-link" onClick={videomodal}></a>
						</div>
						<div class="video-text"><p>Watch video for more information</p></div>
						
						<Modal isOpen={modal} toggle={videomodal} className="video-modal" >
							<ModalBody>
								<iframe src={`${process.env.REACT_APP_BACKENDURL}homepage-video.mp4`} width="100%" height="100%" frameBorder="0" allowFullScreen></iframe>
								<a className="close-modal-link"  onClick={videomodal}><i className="fa fa-times-circle" aria-hidden="true"></i></a>
							</ModalBody>
						</Modal>
					</Col>
				</Row>
			</Container>
		</div>

		{/* Banner Section */}

	    {/* Key Feature Section */}

		<div className="key-feature-sec" id="features">
			<Container>
				<h2 className="center-title">Key Features</h2>
				<Row>
					<Col md="4">
						<div className="key-feature">
							<div className="key-feature-img">
								<img src={featurecost} alt="featurecost"/>
							</div>
							<h6 className="feature-title">{ keyFeatures.title_1 }</h6>
							<div className="feature-desc">
								<p>{ keyFeatures.description_1 }</p>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="key-feature">
							<div className="key-feature-img">
								<img src={featureinstant} alt="featureinstant"/>
							</div>
							<h6 className="feature-title">{ keyFeatures.title_2 }</h6>
							<div className="feature-desc">
								<p>{ keyFeatures.description_2 }</p>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="key-feature">
							<div className="key-feature-img">
								<img src={featurenocontract} alt="featurenocontract"/>
							</div>
							<h6 className="feature-title">{ keyFeatures.title_3 }</h6>
							<div className="feature-desc">
								<p>{ keyFeatures.description_3 }</p>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>

		{/* Key Feature Section */}

		{/* Quick Start Section */}

		<div className="quick-start-sec">
			<Container>
				<ul className="quick-start-tags">
					<li>No thrills </li>
					<li>No subscription</li>
					<li>No commitment</li>
				</ul>
				<div className="quick-start-desc">
					<p>The Market Squawk has taken the concept of the financial audio news services or squawks and made it more accessible for the retail trader. Some traders need that in-depth, blow-by-blow feed of headlines. For others, it is about getting quick access to news that affects your position. It’s about being there for the one or two events in a day that matter to them.</p>
				</div>
				{ isLogin == true ? 
				<Link to={`${process.env.PUBLIC_URL}/settings`} className="common-btn sign-up-btn btn">Dashboard</Link>
				:
				<Link to={`${process.env.PUBLIC_URL}/login?signup=true`} className="common-btn sign-up-btn btn">SIGN ME UP</Link>
				}
			</Container>
		</div>

		{/* Quick Start Section */}

		{/* News Feed Section */}

		<div className="pricing-section home-news-sec" id="news">
			<Container>
				<div className="pricing-desc">
					<h2 className="text-center">News Feed</h2>
				</div>
				<Row>
					<Col md="5">
						<img src={newsfeed.news_feed_image} alt="news-img" />
					</Col>
					<Col md="7">
						<div className="home-news-desc">
							<h4>{newsfeed.news_feed_title}</h4>
							<p>{newsfeed.news_feed_description}</p>
							<ul>
								<li>{newsfeed.news_feed_opt1}</li>
								<li>{newsfeed.news_feed_opt2}</li>
								<li>{newsfeed.news_feed_opt3}</li>
							</ul>

							{ isLogin == true ? 
							<Link to={`${process.env.PUBLIC_URL}/news-feed`} className="secondary-btn  common-btn btn nav-link">Get Started</Link>
							:
							<Link to={`${process.env.PUBLIC_URL}/login?signup=true`} className="secondary-btn  common-btn btn nav-link">Get Started</Link>
							}
						</div>
					</Col>
				</Row>
			</Container>
		</div>

		{/* News Feed Section */}

		{/* About Section */}

		<div className="about-us-section" id="about">
			<Container>
				<Row>
					<Col md="8">
						<div className="about-left-sec">
							<h2>About us</h2>
							<p>{ aboutUs.short_description }</p>
							<ul className="about-list">
								<li>
									<div className="about-list-img">
										<img src={ aboutUs.image_1 } alt="aboutteam"/>
									</div>
									<div className="about-list-desc">
										<p><span className="about-list-title">{ aboutUs.title_1 }</span>{ aboutUs.description_1 }</p>
									</div>
								</li>
								<li>
									<div className="about-list-img">
										<img src={ aboutUs.image_2 } alt="aboutreaserch"/>
									</div>
									<div className="about-list-desc">
										<p><span className="about-list-title">{ aboutUs.title_2 }</span>{ aboutUs.description_2 }</p>
									</div>
								</li>
							</ul>
						</div>
					</Col>
					<Col md="4">
						<div className="about-right-sec">
							<img src={aboutimg} alt="aboutimg"/>
						</div>
					</Col>
				</Row>
			</Container>
			<video  loop="" autoPlay muted="muted" data-origwidth="0" data-origheight="0" loop>
                    <source src={aboutvideo} type="video/mp4" />
             </video>
		</div>

		{/* About Section */}

		{/* Pricing Section */}

		<div className="pricing-section" id="pricing">
			<Container>
				<h2 className="text-center">Our Pricing</h2>
				<Row>
					<Col md="2">
					</Col>
					<Col md="8">
						<div className="pricing-desc text-center">
							<p>{ ourPricing.short_description_1 }</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md="6">
						<h4 className="text-center">Audio + Newsfeed</h4>
						<ul className="pricing-list">
							{ subscription_item_audio_new.map((item,k1) =>{
								var amt = item.amount;
								var t = amt.toString().split(".");
								return ( <li id={ "sub_"+k1 }>
									<span className="plan-days"> { item.interval }</span>
									<h3 className="price">${ t[0] } <span>.{ t[1] }</span></h3>
									<p className="plan-desc">{ item.description }</p>
									{ isLogin == false ? 
									<Link to={`${process.env.PUBLIC_URL}/login?signup=true`} className="buy-now-btn">BUY NOW</Link> : '' }
								</li>)
								}
							)}							
						</ul>
					</Col>
					<Col md="6">
						<h4 className="text-center">Audio</h4>
						<ul className="pricing-list">
							{ subscription_item_audio.map((item,k1) =>{
								var amt = item.amount;
								var t = amt.toString().split(".");
								return ( <li id={ "sub_"+k1 }>
									<span className="plan-days"> { item.interval }</span>
									<h3 className="price">${ t[0] } <span>.{ t[1] }</span></h3>
									<p className="plan-desc">{ item.description }</p>
									{ isLogin == false ? 
									<Link to={`${process.env.PUBLIC_URL}/login?signup=true`} className="buy-now-btn">BUY NOW</Link> : '' }
								</li>)
								}
							)}							
						</ul>
					</Col>
				</Row>
			</Container>
		</div>


		{/* Pricing Section */}

		{/* Company Slider */}

		<Slider {...companyslider} className="company-detail-slider">
			{ ads_item.map( (adt,k2) =>

				<div id={ "cmslider_"+k2 }>
					<div style={{ backgroundImage: 'url(' + adt.background_image + ')' }}>
						<Container>
							<div className="company-slider-details">
								<img src={ adt.image } className="company-logo"/>
								<h5>{ adt.title }</h5>
							</div>
						</Container>
					</div>
				</div>	

			)}			
			
		</Slider>
		
		{/* Company Slider */}

		{/* Blog & Contact Section */}

		<div className="blog-and-contact-sec" id="blog">

			<Container>

				{/* Blog Section */}

				<div className="blog-section">
					<h2>Our Blog</h2>

					{/* For Desktop Nav tabs */}
					<div className="blog-tab-section">
						<Nav tabs>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === '1' })}
									onClick={() => { toggletab('1'); }}
								>
									LATEST
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === '2' })}
									onClick={() => { toggletab('2'); }}
								>
									POPULAR
								</NavLink>
							</NavItem>							
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<Slider {...blogslider} className="blog-slider">

									{ 
										latest_blog_item.map((lbItem,k3) => 
											<div id={ "b1_"+k3 }>
												<div className="blog-slide">
													<div className="blog-img" style={{ backgroundImage: 'url(' + lbItem.image + ')' }}>
													</div>
													<div className="blog-slide-desc">
														<h6>{ parse(lbItem.title.slice(0,20))}</h6>
														{/* parse(lbItem.description) */}
														<p>{  cleanDesc(lbItem.description) }</p>
														<Link to={`${process.env.PUBLIC_URL}/blog-details/${lbItem.slug} `}  className="read-more-link">Read More</Link>
													</div>
												</div>
											</div>
										)
									}
									
								</Slider>
							</TabPane>
							<TabPane tabId="2">
								<Slider {...blogslider} className="blog-slider">
									{ 
										popular_blog_item.map((pbItem,k4) => 
											<div id={ "b2_"+k4 }>
												<div className="blog-slide">
													<div className="blog-img" style={{ backgroundImage: 'url(' + pbItem.image + ')' }}>
													</div>
													<div className="blog-slide-desc">
														<h6>{parse(pbItem.title.slice(0,20))}</h6>
														{/* parse(pbItem.description) */}
														<p>{  cleanDesc(pbItem.description) }</p>
														<Link to={`${process.env.PUBLIC_URL}/blog-details/${pbItem.slug} `} className="read-more-link">Read More</Link>
													</div>
												</div>
											</div>
										)
									}
								</Slider>
							</TabPane>
						</TabContent>
					</div>	

					{/* For Desktop Nav tabs */}

					{/* For Mobile Nav Dropdown */}

					<div className="blog-drp-section">
						 <div className="blog-drpdown-list">
							<select onChange={ toggleOption }>
								<option value="1" >LATEST</option>
								<option value="2" >POPULAR</option>								
							</select>			
						 </div>
						 
						
						 <Slider {...blogslider} className="blog-slider" style={{ display: activeOption == 1 ? 'block' : 'none' }}>
							{ 
								latest_blog_item.map((lbItem,k5) =>
									<div id={ "b5_"+k5 }>
										<div className="blog-slide">
											<div className="blog-img" style={{ backgroundImage: 'url(' + lbItem.image + ')' }}>
											</div>
											<div className="blog-slide-desc">
												<h6>{parse(lbItem.title.slice(0,20))}</h6>
												<p>{  cleanDesc(lbItem.description) }</p>
												<Link to={`${process.env.PUBLIC_URL}/blog-details/${lbItem.slug} `} className="read-more-link">Read More</Link>
											</div>
										</div>
									</div>
								)
							}						
						</Slider>
						 <Slider {...blogslider} className="blog-slider" style={{ display: activeOption == 2 ? 'block' : 'none' }}>
							{ 
								popular_blog_item.map((pbItem,k6) =>
									<div id={ "b6_"+k6 }>
										<div className="blog-slide">
											<div className="blog-img" style={{ backgroundImage: 'url(' + pbItem.image + ')' }}>
											</div>
											<div className="blog-slide-desc">
												<h6>{parse(pbItem.title.slice(0,20))}</h6>
												<p>{  cleanDesc(pbItem.description) }</p>
												<Link to={`${process.env.PUBLIC_URL}/blog-details/${pbItem.slug} `} className="read-more-link">Read More</Link>
											</div>
										</div>
									</div>
								)
							}						
						</Slider>
						

					</div>

					{/* For Mobile Nav Dropdown */}

				</div>

	
				{/* Blog Section */}

				{/* Contact Section */}

				<div className="contact-section" id="contact">
					<h2>Get in touch</h2>
					<div className="contact-desc">
						<p>{ getInTouch.short_description_2 }</p>
					</div>
					<Row>
						<Col md="7">
							<Form className="contact-form" id="contact-form" onSubmit= { handleSubmit }>
								<Row>
									<Col md={6}>
										<FormGroup>
											<Input type="text" name="name"  placeholder="Name*" onChange={ handleChange } />
											{ errors.errName && <p className="error-text"> <em> { errors.errName } </em></p> }
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Input type="email" name="email"  placeholder="Email*" onChange={ handleChange } />
											{ errors.errEmail && <p className="error-text"> <em> { errors.errEmail } </em></p> }
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md="12">
										<FormGroup>
											<Input type="text" name="telephone"  placeholder="Telephone*" onChange={ handleChange } />
											{ errors.errTelephone && <p className="error-text"> <em> { errors.errTelephone } </em></p> }
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md="12">
										<FormGroup>
											<Input type="textarea" name="message" placeholder="Message*" onChange={ handleChange } />
											{ errors.errMessage && <p className="error-text"> <em> { errors.errMessage } </em></p> }
										</FormGroup>
									</Col>
								</Row>
								<Button>SEND</Button>
							</Form>
						</Col>
						<Col md="5">
							<ul className="contact-details">
								<li className="contact-mail">
									<a href={ "mailto:"+ siteConf.email }>{ siteConf.email }</a>
								</li>
								{/* <li className="contact-tel">
									<a href={ "tel:"+ siteConf.contact }>{ siteConf.contact }</a>
								</li> */}
								<li className="contact-address">
									<p>{ parse(siteConf.address) }</p>
								</li>
							</ul>
						</Col>
					</Row>
					
				</div>

				{/* Contact Section */}

			</Container>

		</div>

		<div>
			
		</div>

		{/* Blog & Contact Section */}

		{/* Footer */}

		<FooterSection onClickMenu= { handleMenuClick }/>  
		

		{/* Footer */}

		
		</Fragment>)
	}

const mapStateToProps = state => ({
  userData: state.auth,
})
export default connect(mapStateToProps)(Home)