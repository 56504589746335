const newsFeed = (callback) => { 
  
  const existingScript = document.getElementById('newsFeed');
  if (!existingScript) {
    const bkurl = process.env.REACT_APP_BACKENDURL;
    const script = document.createElement('script');
    script.src = bkurl+'newsfeed.js';
    script.id = 'newsFeed';
    document.body.appendChild(script);
    script.onload = () => { 
      if (callback) callback();
    };

  }
  if (existingScript && callback) callback();
};
export default newsFeed;