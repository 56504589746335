import React, { Component, Fragment ,useState,useEffect } from 'react'
import { Helmet } from 'react-helmet'
// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

import classnames from 'classnames';


// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// News page images
import aboutvideo from "../assets/videos/about-video.mp4";
import newsimg from "../assets/images/news-img.png"
// News page images

import { ToastContainer,toast } from 'react-toastify';
import http from '../httpService/httpService'
import '../../node_modules/react-toastify/dist/ReactToastify.css'

import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
    NavLink,
    TabContent, TabPane, Modal, ModalBody
  } from 'reactstrap';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import InnerMenu from './userLinks.jsx';
import Player from './Player.jsx';
import loadPlayer from './loadPlayer.js';
import Posts from "./Posts";

const postsPerPage = 4;
let arrayForHoldingPosts = [];

const TITLE = 'News | Market Squawk'
const News   = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

	const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(4);
    const [spost, sspost] = useState(4);

    const [activeTab, setActiveTab] = useState('latest');
    const [category, setCategory] = useState([]);
    const [news, setNew] = useState([]);

    const loopWithSlice = (start, end) => {        
        const slicedPosts = news.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
    };


    const toggletab = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    
    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
        sspost(next + postsPerPage);
    };

    useEffect(() => { 

        if(!props.fetched) {
            window.scrollTo(0, 0)
            http.get(process.env.REACT_APP_API_URL + '/news-category-list')
            .then((response) => {
                setCategory(response.data.data);
            });
        }
    
        if(!props.fetched) {
            http.get(process.env.REACT_APP_API_URL + '/news-list?offset=0&limit=10')
            .then((response) => {
                setNew(response.data.data);

                const nsw = response.data.data;
                const slicedPosts = nsw.slice(0, postsPerPage);
                arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
                setPostsToShow(arrayForHoldingPosts);             
            });
        }

        if(!props.fetched) {

          // const script = document.createElement("script");
          // script.src = "https://code.jquery.com/jquery-3.2.1.min.js";
          // script.async = true;
          // document.body.appendChild(script);

          // const script1 = document.createElement("script");
          // script1.src = "//www.livesquawk.com/partner_player_only/js/include_tms_webrtc_only.js?v=3";
          // script1.async = true;
          // document.head.appendChild(script1);
        }

    }, []);

         
    return (
        <Fragment>

        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>

        {/* Header */}

        <Navigation />

        {/* Header */}

        {/* Dashboard-header */}

        <div className="dashboard-header-sec">
            <div className="dashboard-header">
                
            {loaded ? <Player /> : ''}
                {/*<div id="livesquawk_player_outer">
                </div>
                 <Container>
                    <Row>
                        <Col md="3">
                            <div className="volume-section">
                                <p>Volume Adjust</p>
                            </div>
                        </Col>
                        <Col md="6" className="middle-header">
                            <div className="on-air-labels">
                                <span>ON AIR</span>
                                <span className="on-air-icon"></span>
                                <span>ON AIR</span>
                            </div>
                        </Col>
                        <Col md="3">
                        
                        </Col>
                    </Row>
                </Container>*/}
            </div>
            <div className="dahboard-nav-sec">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                        <Col md="6">
                            <InnerMenu/>
                        </Col>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <video autoplay="" loop="" muted="muted" data-origwidth="0" data-origheight="0" autoplay="autoplay" loop playsinline>
                    <source src={aboutvideo} type="video/mp4" />
             </video>
        </div>

        {/* Dashboard-header */}

        {/* All News Sectiom */}

        <div className="all-news-section">
            <Container>
                <h2>Market News</h2>
                
                {/* News Tab for Desktop */}

                <div className="news-tab-section">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'latest' })}
                                onClick={() => { toggletab('latest'); }}
                            >
                                LATEST
                            </NavLink>
                        </NavItem>
                        { category.map(cat =>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === cat.category_id })}
                                    onClick={() => { toggletab(cat.category_id); }}
                                >
                                    { cat.name }
                                </NavLink>
                            </NavItem>
                        )}
                        
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="latest">
                            {/* <ul className="all-news">
                                { news.map(nws =>
                                    <li className="news" key={ nws.id }>
                                        <div className="news-img" style={{ backgroundImage: 'url(' + nws.image + ')' }}>
                                        </div>
                                        <span className="catgory-name" style={{backgroundColor: nws.category_color }}>{nws.category_name}</span>
                                        <div className="news-details">
                                            <div className="news-date-and-time">
                                                <span className="news-date">{nws.date}</span>
                                                <span className="news-time">{nws.time}</span>
                                            </div>
                                            <a target="_blank" href={nws.url} className="news-name">{nws.title}</a>
                                        </div>
                                    </li>
                                )}
                            </ul> */}
                            <Posts postsToRender={postsToShow} />
                            { (news.length > spost) && <a  className="primary-btn btn" onClick={handleShowMorePosts} >LOAD MORE</a> }
                        </TabPane>

                        { category.map(cat =>
                            <TabPane tabId={ cat.category_id} >
                                <ul className="all-news">
                                    { cat.news.map(ns =>
                                        <li className="news">
                                            <div className="news-img" style={{ backgroundImage: 'url(' + ns.image + ')' }}>
                                            </div>
                                            <span className="catgory-name" style={{backgroundColor: ns.category_color }}>{ns.category_name}</span>
                                            <div className="news-details">
                                                <div className="news-date-and-time">
                                                    <span className="news-date">{ns.date}</span>
                                                    <span className="news-time">{ns.time}</span>
                                                </div>
                                                <a href={ns.url} className="news-name">{ns.title}</a>
                                            </div>
                                        </li>
                                    )}  
                                </ul>
                            </TabPane>
                        )}

                    </TabContent>
                </div>

                {/* News Tab for Desktop */}

                {/* News Dropdown for Mobile */}

                <div className="news-drp-section">
                    <div className="news-drp-list">
                        <select>
                            <option value="latest">LATEST</option>
                            { category.map(cat =>
                                <option value={ cat.category_id }>{ cat.name }</option>
                            )}
                        </select>
                        <ul className="all-news latest">
                            { news.map(nws =>
                                <li className="news">
                                    <div className="news-img" style={{ backgroundImage: 'url(' + nws.image + ')' }}>
                                    </div>
                                    <span className="catgory-name" style={{backgroundColor:  nws.category_color }}>{nws.category_name}</span>
                                    <div className="news-details">
                                        <div className="news-date-and-time">
                                            <span className="news-date">{nws.date}</span>
                                            <span className="news-time">{nws.time}</span>
                                        </div>
                                        <a href={nws.url} className="news-name">{nws.title} </a>
                                    </div>
                                </li>
                            )}
                        </ul>
                        {/* <a href="#" className="primary-btn btn">LOAD MORE</a> */}
                    </div>

                </div>

                 {/* News Dropdown for Mobile */}

            </Container>
        </div>

        {/* All News Sectiom */}

        {/* <Modal isOpen={modal} toggle={pagemodal} className="page-modal" >
            <ModalBody>
                <h3>Are You Sure ?</h3>
                <p>Are you sure you want to navigate away from this page? Leaving will STOP your in-page audio-connection... </p>
                <button className="primary-btn btn"> Leave Page Anyway</button>
                <button className="primary-btn btn">Stay on This Page</button>
            </ModalBody>
        </Modal>
        */}
 
         {/* Footer */}
 
         <FooterSection/>    
         
 
         {/* Footer */}
 
 
         </Fragment>)
}
 
 export default News