import React from 'react';
import logo from './logo.svg';
// import './App.css';
import { Route,Switch, Redirect } from 'react-router-dom'
import Home from '../src/views/index.jsx';
{/* import Home from '../views/index';
import Login from '../views/login';
import News from '../views/news';
import Settings from '../views/settings';
import Subscription from '../views/subscription'; */}

function App() {
  return (
   <Home />
  );
}

export default App;
