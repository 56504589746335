import React, { Component, Fragment ,useState } from 'react'
import { Helmet } from 'react-helmet'
// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// Login page images
import stripimg from "../assets/images/strip-img.png"
// Login page images
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
    NavLink,
    Button, Form, FormGroup, Input, Modal, ModalBody
} from 'reactstrap';
import { Link,useParams,withRouter } from 'react-router-dom';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import { verifyEmail ,verifyPassword } from '../helper';
import { ToastContainer,toast } from 'react-toastify';
import http from '../httpService/httpService'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import Loader from '../components/loader.jsx';
const TITLE = 'Reset Password | Market Squawk'
const Reset   = (props) => {
    const urlparams = useParams();
    const [ account , setAccount ] = useState( { email : urlparams.email, password: '',password_confirmation: '',token: urlparams.token } );
    const [ errors , setErrors] = useState( { errPassword : '',errCnfmPassword : ''} );
    const [ loader , setLoader] = useState(false);
    

    const validate = () => {
           
        const errors = {};
        // Password Validation
        if(account.password == ''){
          errors.errPassword = 'Password is required';
        }else if(!verifyPassword(account.password)){
          errors.errPassword = 'Please enter password between 6 to 15 character';
        } 

        // Password Validation
        if(account.password_confirmation == ''){
          errors.errCnfmPassword = 'Confirm Password is required';
        }else if(!verifyPassword(account.password_confirmation)){
          errors.errCnfmPassword = 'Please enter password between 6 to 15 character';
        }else if(account.password !== account.password_confirmation){
            errors.errCnfmPassword = 'Password and  confirm password should be same';
        } 

        setErrors(errors);
        return Object.keys(errors).length === 0 ? null : errors;

    }

    const handleSubmit = e => {

        e.preventDefault();
        let email =account.email; 
        let password =account.password; 
        let token =account.token; 
        let password_confirmation =account.password_confirmation; 
        
        if( validate() === null){
            setLoader(true);

            http.post(process.env.REACT_APP_API_URL+'/reset-password', { email , password, token ,password_confirmation})
            .then((response) => {   
                document.getElementById("reset-form").reset();
                setLoader(false);      
                toast.success(response.data.meta.message);
                props.history.push(process.env.PUBLIC_URL+"/login")  
            })
            .catch((error) => {
              setLoader(false);
              if(error.response && error.response.status != 200){
                 toast.error(error.response.data.meta.message);
              }
            });
        }
       
    }
    
    const handleChange = (e) => {

        const { name , value  } = e.target;
        account[name]=value;
        setAccount(account);
    }

    return (
         <Fragment>

         <Helmet>
          <title>{ TITLE }</title>
         </Helmet>

         {loader && <Loader />} 
         <ToastContainer/>
         {/* Header */}
             
         <Navigation />
 
         {/* Header */}
            
         {/* Reset Section */}
        
        <div className="login-section">
            <Container>
                <div className="login-details">
                    <h2 className="center-title">Reset Password</h2>
                    <Form className="login-form" id="reset-form" onSubmit= { handleSubmit }>
                        <Input type="hidden" name="token" value={ account.token } />
                        <Input type="hidden" name="email" value={ account.email } />   
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <label>Password</label>
                                    <Input type="password" name="password" onChange={ handleChange } />
                                    { errors.errPassword && <p className="error-text"> <em> { errors.errPassword } </em></p> }
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label>Confirm Password</label>
                                    <Input type="password" name="password_confirmation" onChange={ handleChange } />
                                    { errors.errCnfmPassword && <p className="error-text"> <em> { errors.errCnfmPassword } </em></p> }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="forget-footer-sec">
                            <Col md={12}>
                                <div className="login-btns">
                                    <Button>Reset</Button>                                
                                </div>                       
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </div>

        {/* Reset Section */}

        {/* Footer */}

        <FooterSection/>


        {/* Footer */}


        </Fragment>)
}
 
export default withRouter(Reset)