import React, { Component, Fragment ,useState , useEffect,useMemo } from 'react'
import { Helmet } from 'react-helmet'
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement, 
  useStripe, 
  useElements,
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';

// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// Login page images
import stripimg from "../assets/images/strip-img.png"
// Login page images
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button, Form, FormGroup, Input, Modal, ModalBody
} from 'reactstrap';
import { Link,Redirect,withRouter } from 'react-router-dom';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import { verifyEmail ,verifyPassword } from '../helper';
import { ToastContainer,toast } from 'react-toastify';
import http from '../httpService/httpService'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import { store } from '../store.js'
import Loader from '../components/loader.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';
var parse = require('html-react-parser');

const useOptions = () => {
  
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    })    
  );

  return options;
};


const CheckoutForm = (props) => {
    const registerData = props.regData;

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions(); 
    const [ loader , setLoader] = useState(false);
    const signupmodal3 = () => setModal3(!modal3);
    const [modal3, setModal3] = useState(false);

    const handleSubmitStripeForm = async event => {
    event.preventDefault();
    setLoader(true);
    if (!stripe || !elements) {
      setLoader(false);
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });

    if(payload.error !== undefined){
        setLoader(false);
        document.getElementById("stripeErr").innerHTML = payload.error.message;
    }else{
        document.getElementById("stripeErr").innerHTML ='';
        // setLoader(true);

        let full_name =registerData.s_fullname; 
        let email = registerData.s_email;
        let email_confirmation = registerData.s_email_confirm;
        let password = registerData.s_password;
        let password_confirmation = registerData.s_password_confrim;
        let subsctiption_id = registerData.s_subscription;
        let payment_id = payload.paymentMethod.id;

        http.post(process.env.REACT_APP_API_URL+'/register', { full_name ,email,email_confirmation,password,password_confirmation,payment_id,subsctiption_id})
        .then((response) => {  
          setLoader(false); 
          const resData = response.data; 
          
          // toast.success(response.data.meta.message);
          // localStorage.setItem('marketAuthToken',response.data.meta.auth_token); 
          // setModal3(true)
            
          if(resData.meta.subscription && resData.meta.subscription.status == 'incomplete' && resData.meta.subscription.latest_invoice.payment_intent != undefined){              
              handleCardAuth(resData.meta.subscription.latest_invoice.payment_intent,resData.meta.message,resData.meta.auth_token)
          }else{
            toast.success(response.data.meta.message);
            localStorage.setItem('marketAuthToken',response.data.meta.auth_token); 
            setModal3(true)
          }
          
        })
        .catch((error) => {
          setLoader(false);
          if(error.response && error.response.status != 200){
             toast.error(error.response.data.meta.message);
          }
        });

    }
  };


  const handleCardAuth = (paymentIntent,sucMsg,AuthToken) =>{
     stripe.handleCardPayment(paymentIntent.client_secret).then((res) =>{
        if(res.error){
           toast.success('Stripe authentication fail.');
        }else{
            toast.success(sucMsg);
            // // localStorage.setItem('marketAuthToken',response.data.meta.auth_token); 
            localStorage.setItem('marketAuthToken',AuthToken);                 
            // // props.setUserData(response.data.data,response.data.meta.auth_token)
            setModal3(true)

        }
     })
  }
  return (
    <div>
        {loader && <Loader />}
        <form className="checkout-form" onSubmit={handleSubmitStripeForm}>
            <Input type='hidden' id='zfullname' value={ registerData.s_fullname }/>
            <Input type='hidden' id='zemail' value={ registerData.s_email }/>
            <FormGroup>
                <label>ENTER CARD INFO</label>
                <div className="input-grp card-name">
                     <CardNumberElement/>
                </div>
                <div className="input-grp card-month">
                     <CardExpiryElement/>
                </div>
                <div className="input-grp card-cvc">
                     <CardCvcElement/>
                </div>
                <div className="signup-btn-sec">
                    <button className="btn common-btn" type="submit" id='zpay-now-btn' disabled={!stripe || loader}>
                      Pay Now
                    </button>   
                    <img src={stripimg} alt="stripimg"/>             
                </div>
               
            </FormGroup>
        </form>
        <span className="errors" id="stripeErr"></span>

        <Modal isOpen={modal3} toggle={signupmodal3} className="signup-modal" >
            <ModalBody>
                <div className="thankyou-section">
                    <h2>Thank you</h2>
                    <h4>Your account is live!</h4>
                    <p>An email confirmation including the details about your account has been sent to the email address {  registerData.s_email }.</p>
                    <p>​You are good to go! Login to your account and start making the most of your Market Squawk account.</p>
                    <a className="btn common-btn sign-up-btn mrg-left" href={`${process.env.PUBLIC_URL}/news`}>Go to Dashboard</a>
                </div>
            </ModalBody>
            <a className="close-modal-link"  onClick={signupmodal3}></a>
        </Modal>

    </div>
  );
};

const TITLE = 'Login | Market Squawk';
const Login   = (props) => {    

    const [ isOpen, setIsOpen ] = useState(false);
    const [ account , setAccount ] = useState( { email : '', password: ''} );
    const [ errors , setErrors] = useState( { errEmail : '',errPasword : ''} );
    const [ loader , setLoader] = useState(false);
    const [ subscription_item_audio , setSubscription_item_audio] = useState([]);
    const [ subscription_item_audio_new , setSubscription_item_audio_new] = useState([]);
    const [ subValue , setsubValue] = useState({ days : '',amt1:'',amt2:'' });
    const toggle = () => setIsOpen(!isOpen);

    const signupmodal1 = () => {

      setNotifyData({ n_email: ''} )
      setNotifyErrors({ err_n_email: ''} )

      http.get(process.env.REACT_APP_API_URL + '/checkdate')
      .then((response) => {
          let checkdate = response.data.data;
          
          if(checkdate.isHoliday == 'yes'){
              setnonusermsg(checkdate.nonusermsg)
              setModal4(true)
          }else{
           
            setModal1(!modal1)
          }
      }).catch((error) => {
         
      });
    
      
    };

    const [ NotifyData , setNotifyData ] = useState( { n_email: ''} );
    const [ NotifyErrors , setNotifyErrors] = useState( { err_n_email : ''} );

    const handleChangeNotify = (e) => {

        const { name , value  } = e.target;
        const NotifyD = { ...NotifyData };
        NotifyD[name]=value;
        setNotifyData(NotifyD);

    }

    const NotifySubmit = e => {
        
        e.preventDefault();      
        
        if( validate_notify() === null){
           
            let notify_email = NotifyData.n_email; 
            setLoader(true);
            http.post(process.env.REACT_APP_API_URL+'/save-notify-email', { notify_email })
            .then((response) => {
              setLoader(false);
              setNotifyData({ n_email: ''} )
              setNotifyErrors({ err_n_email: ''} )
              toast.success(response.data.meta.message);
                
            })
            .catch((error) => {
              setLoader(false);
              toast.error('Something went wrong.');
            });
            
        }
       
    }

    const validate_notify = () => {           
        const nfErrors = {};
         // Email Validation
        if(NotifyData.n_email === ''){
          nfErrors.err_n_email = 'Email address is required';
        }else if(!verifyEmail(NotifyData.n_email)){
          nfErrors.err_n_email = 'Please enter valid email address';
        } 
        setNotifyErrors(nfErrors);
        return Object.keys(nfErrors).length === 0 ? null : nfErrors;

    }


    const signupmodal2 = () => setModal2(!modal2);
    const signupmodal3 = () => setModal3(!modal3);
    const signupmodal4 = () => setModal4(!modal4);
    const [ nonusermsg,setnonusermsg ] = useState('We are on holiday please enter email address below and we will let you know when we go up.') ;

    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [modal4, setModal4] = useState(false);

    useEffect(() => {
        const parsed = queryString.parse(props.location.search);
        if(parsed && parsed.signup=='true'){
            signupmodal1();
        }

        if(!props.fetched) {
            http.get(process.env.REACT_APP_API_URL + '/subscription-price-list?offset=0&limit=8')
            .then((response) => {
                let subpackage = response.data.data;
                let audio = subpackage.filter(pc => (pc.type == 'audio'));
                let audionews = subpackage.filter(pc => (pc.type == 'newsaudio'));                
                setSubscription_item_audio(audio);
                setSubscription_item_audio_new(audionews);
            });
        }
    }, []);

    const validate = () => {
           
        const errors = {};
         // Email Validation
        if(account.email === ''){
          errors.errEmail = 'Email is required';
        }else if(!verifyEmail(account.email)){
          errors.errEmail = 'Please enter valid email';
        } 

        // Password Validation
        if(account.password === ''){
          errors.errPasword = 'Password is required';
        }else if(!verifyPassword(account.password)){
          errors.errPasword = `Please enter password between 6 to 15 character's`;
        } 
        setErrors(errors);

        return Object.keys(errors).length === 0 ? null : errors;

      }

    const handleSubmit = e => {

        e.preventDefault();

        let email =account.email; 
        let password = account.password;
        
        if( validate() === null){
            setLoader(true);

            http.post(process.env.REACT_APP_API_URL+'/login', { email , password })
            .then((response) => { 
                setLoader(false);   
                toast.success(response.data.meta.message);
                localStorage.setItem('marketAuthToken',response.data.meta.auth_token); 
                // window.location.href = process.env.PUBLIC_URL
                props.setUserData(response.data.data,response.data.meta.auth_token)
                props.history.push(process.env.PUBLIC_URL+"/news")
               
            })
            .catch((error) => {
              setLoader(false);
              if(error.response && error.response.status != 200){
                 toast.error(error.response.data.meta.message);
              }
            });
        }
       
    }
    
    const handleChange = (e) => {

        const { name , value  } = e.target;
        account[name]=value;
        setAccount(account);
    }

    /* Register User */

    const [ registerData , setregisterData ] = useState( { s_term:0,s_fullname : '', s_email: '',s_email_confirm:'',s_password:'',s_password_confrim:'',s_subscription:'' } );
    const [ registerErrors , setregisterErrors] = useState( { err_s_term:'',err_s_fullname : '',err_s_email : '',err_s_email_confirm: '',err_s_password : '', err_s_password_confrim:'',err_s_subscription:''} );

    const validate_register = () => {
           
        const registerErrors = {};

        //Full Nme 
        if(registerData.s_fullname === ''){
          registerErrors.err_s_fullname = 'Full name is required';
        }

         // Email Validation
        if(registerData.s_email === ''){
          registerErrors.err_s_email = 'Email is required';
        }else if(!verifyEmail(registerData.s_email)){
          registerErrors.err_s_email = 'Please enter valid email';
        } 

        //Confirm Email
        if(registerData.s_email_confirm === ''){
          registerErrors.err_s_email_confirm = 'Confirm email is required';
        }else if(!verifyEmail(registerData.s_email_confirm)){
          registerErrors.err_s_email_confirm = 'Please enter valid email address';
        }else if( registerData.s_email != registerData.s_email_confirm){
          registerErrors.err_s_email_confirm = 'Please enter confirm email same as email';
        }  

        // Password Validation
        if(registerData.s_password === ''){
          registerErrors.err_s_password = 'Password is required';
        }else if(!verifyPassword(registerData.s_password)){
          registerErrors.err_s_password = `Please enter password between 6 to 15 character's`;
        } 

        //Confirm Email
        if(registerData.s_password_confrim === ''){
          registerErrors.err_s_password_confrim = 'Confirm password is required';
        }else if(!verifyPassword(registerData.s_password_confrim)){
          registerErrors.err_s_password_confrim = 'Please enter password between 6 to 15 character';
        }else if( registerData.s_password != registerData.s_password_confrim){
          registerErrors.err_s_password_confrim = 'Please enter confirm password same as password';
        }

        if(registerData.s_subscription === ''){
          registerErrors.err_s_subscription = 'Please select a subscription';
        }

        if(registerData.s_term === 0 || registerData.s_term === ""){
          registerErrors.err_s_term = 'Please accept terms and condition';
        }

        setregisterErrors(registerErrors);

        return Object.keys(registerErrors).length === 0 ? null : registerErrors;

    }

    const register = e => {

        
        e.preventDefault();       
        
        if( validate_register() === null){

            let full_name =registerData.s_fullname; 
            let email = registerData.s_email;
            let email_confirmation = registerData.s_email_confirm;
            let password = registerData.s_password;
            let password_confirmation = registerData.s_password_confrim;
            let subsctiption_id = registerData.s_subscription;

            setLoader(true);
            http.post(process.env.REACT_APP_API_URL+'/validate-user', { full_name ,email,email_confirmation,password,password_confirmation,subsctiption_id })
            .then((response) => { 
                setLoader(false);   
                setModal1(false)
                setModal2(true)
            })
            .catch((error) => {
              setLoader(false);
              if(error.response && error.response.status != 200){
                 toast.error(error.response.data.meta.message);
              }
            });
            
        }
       
    }

    const handleChangeReg = (e) => {

        const { name , value  } = e.target;
        const regD = { ...registerData };
        regD[name]=value;
        setregisterData(regD);

    }

    const handleChangePlan = (e) => { 
        const regD = { ...registerData };
        regD['s_subscription']=e.currentTarget.value;
        setregisterData(regD);

        if(e.currentTarget.selectedOptions[0].getAttribute("days")){
          const days = e.currentTarget.selectedOptions[0].getAttribute("days");
          const amount = e.currentTarget.selectedOptions[0].getAttribute("amount");
          var amt = amount.split(".");
          
          const newAmt = { ...subValue };
          newAmt['days'] = days;
          newAmt['amt1'] = amt[0];
          newAmt['amt2'] = amt[1];
          setsubValue(newAmt);
        }
       

    } 

    const handleChecked = (e) => { 
        const newArr = { ...registerData }
        newArr['s_term']=e.currentTarget.checked == true ? 1 : 0 ;
        setregisterData(newArr);

    }

    //Stripe     
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    return (
         <Fragment>

         <Helmet>
          <title>{ TITLE }</title>
        </Helmet>

         {loader && <Loader />} 

         {/* <ToastContainer/> */}
         {/* Header */}
             
         <Navigation />
 
         {/* Header */}
            
        {/* Login Section */}
        
        <div className="login-section">
            <Container>
                <div className="login-details">
                    <h2 className="center-title">User Login</h2>
                    <Form className="login-form" onSubmit= { handleSubmit }>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <label>EMAIL ADDRESS</label>
                                    <Input type="email" name="email" onChange={ handleChange } />
                                    { errors.errEmail && <p className="error-text"> <em> { errors.errEmail } </em></p> }
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label>YOUR PASSWORD</label>
                                    <Input type="password" name="password" onChange={ handleChange } />
                                    { errors.errPasword && <p className="error-text"> <em> { errors.errPasword }  </em></p> }  
                                    <Link to={`${process.env.PUBLIC_URL}/forgot-password`} className="forget-link" >Forgot?</Link>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <div className="login-btns">
                                <Button>LOG ME IN</Button>
                                <div className="remember-checkbox">
                                    <input type="checkbox" name="remember-input" />
                                    <span><i class="fa fa-check" aria-hidden="true"></i></span>
                                    <p>Remember me</p>
                                </div>
                            </div>
                        </Col>
                        </Form>
                        <Col md={12}>
                            <p className="register-link">Don't have an account yet? <a onClick={signupmodal1}>Register here</a></p>
                            <Modal isOpen={modal1} toggle={signupmodal1} className="signup-modal" >
                                <ModalBody>
                                    <h2>Sign Up</h2>
                                    <Form className="signup-form"> 
                                        <FormGroup>
                                            <label>FULL NAME</label>
                                            <input type="text" name="s_fullname" id="s_fullname" placeholder="Name & Surname" onChange={ handleChangeReg } value={registerData.s_fullname} />
                                            { registerErrors.err_s_fullname && <p className="error-text"> <em> { registerErrors.err_s_fullname } </em></p> }
                                        </FormGroup>
                                        <FormGroup></FormGroup>
                                        <FormGroup>
                                            <label>CONTACT EMAIL</label>
                                            <input type="email" name="s_email" id='s_email'  placeholder="Email" onChange={ handleChangeReg } value={registerData.s_email}/>
                                            { registerErrors.err_s_email && <p className="error-text"> <em> { registerErrors.err_s_email } </em></p> }
                                        </FormGroup>
                                        <FormGroup>
                                            <label className="blank-label"></label>
                                            <input type="email" name="s_email_confirm" id="s_email_confirm" placeholder="Email Confirmation" onChange={ handleChangeReg } value={registerData.s_email_confirm}/>
                                            { registerErrors.err_s_email_confirm && <p className="error-text"> <em> { registerErrors.err_s_email_confirm } </em></p> }
                                        </FormGroup>
                                        <FormGroup>
                                            <label>ACCOUNT PASSWORD</label>
                                            <input type="password" name="s_password" id="s_password" placeholder="Enter Password" onChange={ handleChangeReg } value={registerData.s_password}/>
                                            { registerErrors.err_s_password && <p className="error-text"> <em> { registerErrors.err_s_password } </em></p> }
                                        </FormGroup>
                                        <FormGroup>
                                            <label className="blank-label"></label>
                                            <input type="password" name="s_password_confrim" placeholder="Retype Password" onChange={ handleChangeReg } value={registerData.s_password_confrim}/>
                                            { registerErrors.err_s_password_confrim && <p className="error-text"> <em> { registerErrors.err_s_password_confrim } </em></p> }
                                        </FormGroup>
                                        <FormGroup className="full-width">
                                            <label>SELECT PACKAGE</label>
                                            {/* <select name="s_subscription" id="s_subscription" onChange={ handleChangePlan }>
                                                <option value=''>Select Plan</option>
                                                { subscription_item.map(item => 
                                                    <option value={ item.id }  days={ item.interval } amount ={ item.amount }  selected ={registerData.s_subscription == item.id ? 'selected' : ''}>{ item.interval } Subscription ( {item.type == 'audio' ? 'Audio Only' : 'Audio + News' } ) - ${ item.amount }</option>
                                                )}                                               
                                            </select> */}

                                            <select name="s_subscription" id="s_subscription" onChange={ handleChangePlan }>
                                                <option value=''>Select Plan</option>

                                                <optgroup label="Audio + Newsfeed">
                                                  { subscription_item_audio_new.map((item,k) => 
                                                      <option  value={ item.id }  days={ item.interval } amount ={ item.amount }  >{ item.interval } Subscription - ${ item.amount }</option>
                                                  )} 
                                                </optgroup>

                                                 <optgroup label="Audio">
                                                  { subscription_item_audio.map((item,k) => 
                                                      <option  value={ item.id }  days={ item.interval } amount ={ item.amount }  >{ item.interval } Subscription - ${ item.amount }</option>
                                                  )} 
                                                </optgroup>
                                                
                                            </select>
                                            { registerErrors.err_s_subscription && <p className="error-text"> <em> { registerErrors.err_s_subscription } </em></p> }
                                        </FormGroup>
                                        <div className="remember-checkbox">
                                            <input type="checkbox" name="s_term" id="s_term" onClick={ handleChecked }  />
                                            <span>
                                             <i class="fa fa-check" aria-hidden="true"></i> 
                                            </span> 
                                            <p>I agree to the <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} target='_blank'>terms of use</a></p>
                                            { registerErrors.err_s_term && <p className="error-text"> <em> { registerErrors.err_s_term } </em></p> }
                                        </div>
                                        <button type="button" className="btn common-btn" id='checkout-btn' onClick={ register } >CHECKOUT</button>
                                    </Form>
                                </ModalBody>
                                <a className="close-modal-link"  onClick={signupmodal1}></a>
                            </Modal>
                            <Modal isOpen={modal2} toggle={signupmodal2} className="signup-modal" >
                                <ModalBody>
                                    <h2>Sign Up</h2>
                                    <div className="signup-desc-sec">
                                        <div className="signup-desc">
                                            <h4>Your Package</h4>
                                            <p>Your Live Subscription will auto-renew after the initial period has come to an end. You can cancel anytime.</p>
                                        </div>
                                        <div className="signup-plan-sec">
                                            <div className="signup-plan-details">
                                                <span className="plan-days">{ subValue.days }</span>
                                                <h3>${ subValue.amt1 }<span>.{ subValue.amt2 }</span></h3>
                                                <p>Follow a story through</p>
                                            </div>
                                            <a href="javascript:" onClick={ setModal2, setModal1}>UPDATE</a>
                                        </div>
                                    </div>
                                    <div className="checkout-sec">
                                        <h4>Checkout</h4>
                                        <p>Our payment system is secured and powered by Stripe</p>
                                        <Elements stripe={stripePromise}>
                                          <CheckoutForm regData ={ registerData } />
                                        </Elements>
                                        {/* <form className="checkout-form" onSubmit={ handleSubmitStripe }>
                                            <FormGroup>
                                                <label>ENTER CARD INFO</label>
                                                <div className="input-grp card-name">
                                                     <input type="text" name="Card Number" placeholder="Card Number"/>
                                                </div>
                                                <div className="input-grp card-month">
                                                     <input type="text" name="expiry month" placeholder="MM/YY"/>
                                                </div>
                                                <div className="input-grp card-cvc">
                                                     <input type="password" name="cvc" placeholder="CVC"/>
                                                </div>
                                                <div className="signup-btn-sec">
                                                    <button className="btn common-btn" type="button" onClick={signupmodal2,signupmodal3}>PAY NOW</button>
                                                    <img src={stripimg} alt="stripimg"/>
                                                </div>
                                            </FormGroup> 

                                            <FormGroup>
                                                <label>ENTER CARD INFO</label>
                                                <div className="input-grp card-name">
                                                     <CardNumberElement/>
                                                </div>
                                                <div className="input-grp card-month">
                                                     <CardExpiryElement/>
                                                </div>
                                                <div className="input-grp card-cvc">
                                                     <CardCvcElement/>
                                                </div>
                                                <div className="signup-btn-sec">
                                                    <button className="btn common-btn" type="submit" disabled={!stripe}>
                                                      Pay Now
                                                    </button>   
                                                    <img src={stripimg} alt="stripimg"/>             
                                                </div>
                                            </FormGroup>

                                        </form> */}
                                    </div>
                                </ModalBody>
                                <a className="close-modal-link"  onClick={signupmodal2}></a>
                            </Modal>
                            <Modal isOpen={modal3} toggle={signupmodal3} className="signup-modal" >
                                <ModalBody>
                                    <div className="thankyou-section">
                                        <h2>Thank you</h2>
                                        <h4>Your account is live!</h4>
                                        <p>An email confirmation including the details about your account has been sent to the email address { registerData.s_email }. </p>
                                        <p>​You are good to go! Login to your account and start making the most of your Market Squawk account.</p>
                                        <a className="btn common-btn sign-up-btn"> LOG ME IN</a>
                                    </div>
                                </ModalBody>
                                <a className="close-modal-link"  onClick={signupmodal3}></a>
                            </Modal>

                            <Modal isOpen={modal4} toggle={signupmodal4} className="signup-modal" >
                                <ModalBody>
                                    <div className="thankyou-section">
                                        {/* <h2>Thank You</h2> 
                                        <h4>Your account is live!</h4> */}
                                        <p>{ parse(nonusermsg) }</p>
                                        
                                        <Form className="signup-form">                                         
                                          <FormGroup style={{width:'100%',textAlign: 'center'}} >
                                              <label>Notify me when service is back</label>
                                              <input type="email" name="n_email" id='n_email'  placeholder="Enter Email Address" onChange={ handleChangeNotify } value={ NotifyData.n_email}/>
                                              { NotifyErrors.err_n_email && <p className="error-text"> <em> { NotifyErrors.err_n_email } </em></p> }
                                              <button type="button" className="btn common-btn" id='checkout-btn' onClick={ NotifySubmit } >Submit</button>
                                          </FormGroup>    
                                          
                                      </Form>

                                    </div>
                                </ModalBody>
                                <a className="close-modal-link"  onClick={signupmodal4}></a>
                            </Modal>

                        </Col>                    
                </div>
            </Container>
        </div>

        {/* Login Section */}
 
         {/* Footer */}
 
         <FooterSection/>         
 
         {/* Footer */}
 
 
         </Fragment>)
}
const mapStateToProps = {
};


const mapDispatchToProps = dispatch => ({
  setUserData: (data,token) => dispatch({ type:'USER_PROFILE' , payload: { data: data,token } })
});

export default compose(
  withRouter,
  connect('', mapDispatchToProps)
)(Login);
