import React, { Component, Fragment ,useState,useEffect } from 'react'
import http from '../httpService/httpService'
import { ToastContainer,toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import loadPlayer from './loadPlayer.js';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button, Form, FormGroup, Input, Modal, ModalBody
} from 'reactstrap';
var parse = require('html-react-parser');

const Player = (props) => {
	const [ expired , setExpired ] = useState( 0);
	const [ loaded, setLoaded] = useState(false);
    const [ nonusermsg,setnonusermsg ] = useState('We are on holiday please enter email address below and we will let you know when we go up.') ;
    const signupmodal4 = () => setModal4(!modal4);
    const [modal4, setModal4] = useState(false);
	useEffect(() => {        
        
        if(!props.fetched) {
            const token = localStorage.getItem('marketAuthToken');
            http.get(process.env.REACT_APP_API_URL + '/user-view-profile',{
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then((response) => {
            	setExpired(response.data.data.expired);
            	loadPlayer();

                if(response.data.data.stripe_status == 'pending'){
                    toast.error('Your payment is in process...');
                }
            	else if(response.data.data.expired == 0){
            		toast.error('Please subscribe to continue...');
            	}else{
                    
                }                           
            });
        }  

        setTimeout(
            function() {
                http.get(process.env.REACT_APP_API_URL + '/checkdate')
                  .then((response) => {
                      let checkdate = response.data.data;
                      
                      if(checkdate.isHoliday == 'yes'){      
                          setnonusermsg(checkdate.existingusermsg)        
                          setModal4(true)
                      }
                  }).catch((error) => {
                     
                  });    

                  }
            .bind(this),
            5000
        );  

    }, []);

    return (
        <Fragment>
        { expired == 1 ? <div id="livesquawk_player_outer">
            </div> : '' }   

            <Modal isOpen={modal4} toggle={signupmodal4} className="signup-modal" >
                <ModalBody>
                    <div className="thankyou-section">
                        {/* <h2>Thank You</h2> 
                        <h4>Your account is live!</h4> */}
                        <p>{ parse(nonusermsg) }</p>
                        
                        

                    </div>
                </ModalBody>
                <a className="close-modal-link"  onClick={signupmodal4}></a>
            </Modal>         
        </Fragment>
    )

};
export default Player;