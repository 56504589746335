import React from "react";
const Posts = ({ postsToRender }) => {return (
    <ul className="all-news">
        { postsToRender.map(nws =>
            <li className="news" key={ nws.id }>
                <div className="news-img" style={{ backgroundImage: 'url(' + nws.image + ')' }}>
                </div>
                <span className="catgory-name" style={{backgroundColor: nws.category_color }}>{nws.category_name}</span>
                <div className="news-details">
                    <div className="news-date-and-time">
                        <span className="news-date">{nws.date}</span>
                        <span className="news-time">{nws.time}</span>
                    </div>
                    <a target="_blank" href={nws.url} className="news-name">{nws.title}</a>
                </div>
            </li>
        )}
    </ul>
  );
};
export default Posts;