import React, { Component, Fragment ,useState } from 'react'
import { Helmet } from 'react-helmet'
// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// Login page images
import stripimg from "../assets/images/strip-img.png"
// Login page images
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
    NavLink,
    Button, Form, FormGroup, Input, Modal, ModalBody
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import { verifyEmail ,verifyPassword } from '../helper';
import { ToastContainer,toast } from 'react-toastify';
import http from '../httpService/httpService'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import Loader from '../components/loader.jsx';

const acData = { email : ''};
const TITLE = 'Forgot Password | Market Squawk'
const Login   = (props) => {
    
    const [ account , setAccount ] = useState( acData );
    const [ errors , setErrors] = useState( { errEmail : ''} );
    const [ loader , setLoader] = useState(false);
    

    const validate = () => {
           
        const errors = {};
         // Email Validation
        if(account.email === ''){
          errors.errEmail = 'Email is required field';
        }else if(!verifyEmail(account.email)){
          errors.errEmail = 'Please enter valid email';
        } 
        setErrors(errors);
        return Object.keys(errors).length === 0 ? null : errors;

      }

    const handleSubmit = e => {

        e.preventDefault();
        let email =account.email; 
        
        if( validate() === null){
            setLoader(true);

            http.post(process.env.REACT_APP_API_URL+'/forgot-password', { email })
            .then((response) => {   
                document.getElementById("forgot-form").reset();
                setAccount(acData);
                setLoader(false);      
                toast.success(response.data.meta.message);
                  
            })
            .catch((error) => {
              setLoader(false);
              if(error.response && error.response.status != 200){
                 toast.error(error.response.data.meta.message);
              }
            });
        }
       
    }
    
    const handleChange = (e) => {

        const { name , value  } = e.target;
        account[name]=value;
        setAccount(account);
    }

    return (
         <Fragment>

         <Helmet>
          <title>{ TITLE }</title>
         </Helmet>

         {loader && <Loader />} 
         <ToastContainer/>
         {/* Header */}
             
         <Navigation />
 
         {/* Header */}
            
         {/* Reset Section */}
        
        <div className="login-section">
            <Container>
                <div className="login-details">
                    <h2 className="center-title">Forgot Password</h2>
                    <Form className="login-form forget-form" id="forgot-form" onSubmit= { handleSubmit }>
                        <Row>
                            <Col md={ {size: 6 ,offset: 3}}>
                                <FormGroup>
                                    <label>EMAIL ADDRESS</label>
                                    <Input type="email" name="email" onChange={ handleChange } />
                                    { errors.errEmail && <p className="error-text"> <em> { errors.errEmail } </em></p> }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="forget-footer-sec">
                            <Col md={12}>
                                <div className="login-btns">
                                    <Button>Submit</Button>                                
                                </div>
                                <p className="register-link">Back to <Link to={`${process.env.PUBLIC_URL}/login`} >login</Link></p>                            
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </div>

        {/* Reset Section */}

        {/* Footer */}

        <FooterSection/>


        {/* Footer */}


        </Fragment>)
}
 
export default Login