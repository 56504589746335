import React, { Component, Fragment ,useState,useEffect } from 'react'
import { Helmet } from 'react-helmet'
// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

import classnames from 'classnames';

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// News page images
import aboutvideo from "../assets/videos/about-video.mp4";
import newsimg from "../assets/images/news-img.png"
// News page images

import { ToastContainer,toast } from 'react-toastify';
import http from '../httpService/httpService'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import newsFeed from './newsfeed.js';

import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
    NavLink,
    TabContent, TabPane, Modal, ModalBody
  } from 'reactstrap';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import InnerMenu from './userLinks.jsx';
import Player from './Player.jsx';
import loadPlayer from './loadPlayer.js';
import Posts from "./Posts";

const postsPerPage = 4;
let arrayForHoldingPosts = [];

const TITLE = 'News | Market Squawk'
const NewsNew   = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

	const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(4);
    const [spost, sspost] = useState(4);

    const [activeTab, setActiveTab] = useState('latest');
    const [category, setCategory] = useState([]);
    const [news, setNew] = useState([]);

    const loopWithSlice = (start, end) => {        
        const slicedPosts = news.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
    };


    const toggletab = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    
    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
        sspost(next + postsPerPage);
    };

    const [ expired , setExpired ] = useState(1);
    const [ type , setType ] = useState('');
    useEffect(() => {        
        
        if(!props.fetched) {
            const token = localStorage.getItem('marketAuthToken');
            http.get(process.env.REACT_APP_API_URL + '/user-view-profile',{
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then((response) => {
                setExpired(response.data.data.expired);
                setType(response.data.data.type);
                newsFeed()                                             
            });
        }        

    }, []);
         
    return (
        <Fragment>

        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>

        {/* Header */}

        <Navigation />

        {/* Header */}

        {/* Dashboard-header */}

        <div className="dashboard-header-sec">
            <div className="dashboard-header">
                
            {loaded ? <Player /> : ''}
                
                {/* 
                <div id="livesquawk_player_outer">
                </div>
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="volume-section">
                                <p>Volume Adjust</p>
                            </div>
                        </Col>
                        <Col md="6" className="middle-header">
                            <div className="on-air-labels">
                                <span>ON AIR</span>
                                <span className="on-air-icon"></span>
                                <span>ON AIR</span>
                            </div>
                        </Col>
                        <Col md="3">
                        
                        </Col>
                    </Row>
                </Container> */}
            </div>
            <div className="dahboard-nav-sec">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                        <Col md="6">
                            <InnerMenu/>
                        </Col>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <video autoplay="" loop="" muted="muted" data-origwidth="0" data-origheight="0" autoplay="autoplay" loop playsinline>
                    <source src={aboutvideo} type="video/mp4" />
             </video>
        </div>

        {/* Dashboard-header */}

        {/* All News Sectiom */}

        <div className="all-news-section">
            <Container>
                <h2>Market News</h2>
                { (expired == 1 && type=='newsaudio') ? <div id="livesquawk_messenger_outer"></div> : <h6 className='sub-newfeed'>Please subscribe for news feed.</h6> } 
            </Container>
        </div>

        {/* All News Sectiom */}

        {/* <Modal isOpen={modal} toggle={pagemodal} className="page-modal" >
            <ModalBody>
                <h3>Are You Sure ?</h3>
                <p>Are you sure you want to navigate away from this page? Leaving will STOP your in-page audio-connection... </p>
                <button className="primary-btn btn"> Leave Page Anyway</button>
                <button className="primary-btn btn">Stay on This Page</button>
            </ModalBody>
        </Modal>
        */}
 
         {/* Footer */}
 
         <FooterSection/>    
         
 
         {/* Footer */}
 
 
         </Fragment>)
}
 
export default NewsNew